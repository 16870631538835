const SHOPIFY_DOMAIN = process.env.REACT_APP_SHOPIFY_SHOP_DOMAIN;
const STOREFRONT_ACCESS_TOKEN =
  process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN;

const storefrontFetch = async (query, variables = {}) => {
  const response = await fetch(
    `https://${SHOPIFY_DOMAIN}/api/2024-10/graphql.json`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
      },
      body: JSON.stringify({ query, variables }),
    },
  );

  if (!response.ok) {
    throw new Error(`Shopify API error: ${response.statusText}`);
  }

  const result = await response.json();
  if (result.errors) {
    throw new Error(`GraphQL errors: ${JSON.stringify(result.errors)}`);
  }

  return result.data;
};

export const fetchProducts = async (creatorHandle, filters, after) => {
  const query = `
    query GetProducts($productFilters: [ProductFilter!], $query: String!) {
      search(first: 25, query: $query, productFilters: $productFilters, types: PRODUCT) {
        productFilters {
          id
          label
          type
          values {
            id
            label
            count
            input
          }
        }
        edges {
          node {
            ... on Product {
              id
              title
              description
              images(first: 1) {
                edges {
                  node {
                    originalSrc
                    altText
                  }
                }
              }
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              productType
              tags
              vendor
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  `;

  const variables = {
    productFilters: filters.map((filter) => JSON.parse(filter)),
    query: `NOT tag:${creatorHandle}`,
  };

  const { search: data } = await storefrontFetch(query, variables);
  return {
    products: data.edges.map((edge) => ({
      ...edge.node,
      addedToStore: edge.node.tags.includes(`${creatorHandle}`),
      image: edge.node.images.edges[0]?.node.originalSrc,
      price: `${edge.node.priceRange.minVariantPrice.amount} ${edge.node.priceRange.minVariantPrice.currencyCode}`,
    })),
    filters: data.productFilters,
    pageInfo: data.pageInfo,
    totalCount: data.totalCount,
  };
};

export const fetchCreatorCollection = async (
  creatorHandle,
  filters = [],
  first = 250,
) => {
  const query = `
    query GetCreatorCollection($handle: String!, $first: Int!, $query: String) {
      collection(handle: $handle) {
        products(first: $first, query: $query) {
          filters {
            id
            label
            type
            values {
              id
              label
              count
              input
            }
          }
          edges {
            node {
              id
              title
              description
              handle
              priceRange {
                minVariantPrice {
                  amount
                  currencyCode
                }
              }
              images(first: 1) {
                edges {
                  node {
                    originalSrc
                    altText
                  }
                }
              }
              productType
              variants(first: 250) {
                edges {
                  node {
                    id
                    title
                    price {
                      amount
                      currencyCode
                    }
                    availableForSale
                    selectedOptions {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const queryString = filters
    .map((filter) => {
      return `filter.${filter.id}:${filter.value}`;
    })
    .join(' AND ');

  const variables = {
    handle: `${creatorHandle}-collection`,
    first,
    query: queryString,
  };

  const data = await storefrontFetch(query, variables);
  return data.collection?.products.edges.map((edge) => edge.node);
};

export const createCart = async () => {
  const mutation = `
      mutation cartCreate {
        cartCreate {
          cart {
            id
            checkoutUrl
          }
        }
      }
    `;

  const response = await storefrontFetch(mutation);
  return response.cartCreate.cart;
};

export const addToCart = async (cartId, variantId, quantity = 1) => {
  console.log('Adding to cart with:', { cartId, variantId, quantity });

  const mutation = `
    mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          id
          lines(first: 100) {
            edges {
              node {
                id
                quantity
                cost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                      altText
                    }
                    price {
                      amount
                      currencyCode
                    }
                    product {
                      title
                      images(first: 1) {
                        edges {
                          node {
                            url
                            altText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        userErrors {
          field
          message
          code
        }
      }
    }
  `;

  const variables = {
    cartId,
    lines: [
      {
        merchandiseId: variantId,
        quantity,
      },
    ],
  };

  try {
    const response = await storefrontFetch(mutation, variables);
    console.log('Full addToCart response:', response);

    if (response.cartLinesAdd.cart === null) {
      if (response.cartLinesAdd.userErrors?.length > 0) {
        const errorMessage = response.cartLinesAdd.userErrors
          .map((error) => error.message)
          .join(', ');
        throw new Error(`Failed to add to cart: ${errorMessage}`);
      }
      throw new Error('Failed to add to cart: Cart returned null');
    }

    return response.cartLinesAdd.cart;
  } catch (error) {
    console.error('Error in addToCart:', error);
    throw error;
  }
};

export const removeFromCart = async (cartId, lineId) => {
  const mutation = `
    mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          id
          checkoutUrl
          lines(first: 100) {
            edges {
              node {
                id
                quantity
                cost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                      altText
                    }
                    price {
                      amount
                      currencyCode
                    }
                    product {
                      title
                      images(first: 1) {
                        edges {
                          node {
                            url
                            altText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const variables = {
    cartId,
    lineIds: [lineId],
  };

  const response = await storefrontFetch(mutation, variables);
  return response.cartLinesRemove.cart;
};

export const getCart = async () => {
  const cartId = localStorage.getItem('cartId');
  if (!cartId) return null;

  const query = `
    query getCart($cartId: ID!) {
      cart(id: $cartId) {
        id
        checkoutUrl
        lines(first: 100) {
          edges {
            node {
              id
              quantity
              cost {
                totalAmount {
                  amount
                  currencyCode
                }
              }
              merchandise {
                ... on ProductVariant {
                  id
                  title
                  image {
                    url
                    altText
                  }
                  price {
                    amount
                    currencyCode
                  }
                  product {
                    title
                    images(first: 1) {
                      edges {
                        node {
                          url
                          altText
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const variables = { cartId };

  try {
    const response = await storefrontFetch(query, variables);
    return response.cart;
  } catch (error) {
    console.error('Error fetching cart:', error);
    localStorage.removeItem('cartId');
    return null;
  }
};

export const getProductVariantByHandleSku = async (handle, sku) => {
  try {
    const query = `query getProductVariantByHandleSku($handle: String!) {
      product(handle: $handle) {
        id
        title
        description
        images(first: 1) {
          edges {
            node {
              originalSrc
              altText
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        productType
        tags
        vendor
        variants(first: 250) {
          edges {
            node {
              id
              title
              sku
              image {
                url
                altText
              }
              compareAtPrice {
                amount
                currencyCode
              }
              price {
                amount
                currencyCode
              }
            }
          }
        }
      }
    }`;

    const variables = { handle };

    const response = await storefrontFetch(query, variables);
    const product = response.product;

    if (!product) {
      console.warn(`Product not found for handle: ${handle}`);
      return null;
    }

    if (!product.variants?.edges) {
      console.warn(`No variants found for product: ${handle}`);
      return null;
    }

    for (const variantEdge of product.variants.edges) {
      const variant = variantEdge.node;
      if (variant.sku === sku) {
        return {
          product: product,
          ...variant,
        };
      }
    }

    console.warn(`No variant found with SKU ${sku} for product ${handle}`);
    return null;
  } catch (error) {
    console.error(`Error fetching product ${handle}:`, error);
    return null;
  }
};

export const updateCartLines = async (cartId, lineId, quantity) => {
  const mutation = `
    mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          id
          checkoutUrl
          lines(first: 100) {
            edges {
              node {
                id
                quantity
                cost {
                  totalAmount {
                    amount
                    currencyCode
                  }
                }
                merchandise {
                  ... on ProductVariant {
                    id
                    title
                    image {
                      url
                      altText
                    }
                    price {
                      amount
                      currencyCode
                    }
                    product {
                      title
                      images(first: 1) {
                        edges {
                          node {
                            url
                            altText
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const variables = {
    cartId,
    lines: [
      {
        id: lineId,
        quantity
      }
    ]
  };

  const response = await storefrontFetch(mutation, variables);
  return response.cartLinesUpdate.cart;
};
