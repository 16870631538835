import React from 'react';
import AssistantNavigation from '../components/navigation/assistant-navigation/assistant-navigation.component';
import { ProjectsProvider } from '../context/project.context';
import { ProjectMessagesProvider } from '../context/messages.context';
import { NavigationProvider } from '../context/navigation.context';

const AssistantLayout = ({ user }) => {
  return (
    <NavigationProvider>
      <ProjectsProvider>
        <ProjectMessagesProvider>
          <AssistantNavigation />
        </ProjectMessagesProvider>
      </ProjectsProvider>
    </NavigationProvider>
  );
};

export default AssistantLayout;
