import React, { createContext, useState } from 'react';

export const NavigationContext = createContext({
  isCollapsed: false,
  setIsCollapsed: () => {},
});

export const NavigationProvider = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <NavigationContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      {children}
    </NavigationContext.Provider>
  );
};