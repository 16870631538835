import React, { useEffect, useState } from 'react';
import MuiButton from '@mui/material/Button';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useCart } from '../../context/cart.context';
import { getCart, createCart } from '../../utils/api/shopify';
import './cart.styles.css';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid2';
import ButtonGroup from '@mui/material/ButtonGroup';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Cart = ({open, onClose}) => {
  const { cart, removeItemFromCart, updateItemQuantity } = useCart();
  const isMobile = useMediaQuery('(max-width: 900px)');
  const isSmallMobile = useMediaQuery('(max-width: 500px)');

  const handleCheckout = () => {
    console.log('cart', cart);
    if (cart?.checkoutUrl) {
      window.open(cart.checkoutUrl, '_blank');
    } else {
      console.error('No checkout URL available');
    }
  };

  const handleQuantityChange = async (lineId, currentQuantity, change) => {
    const newQuantity = currentQuantity + change;
    if (newQuantity < 1) {
      await removeItemFromCart(lineId);
    } else {
      await updateItemQuantity(lineId, newQuantity);
    }
  };

  const CheckoutButton = styled(MuiButton)({
    width: '200px', 
    borderRadius: '23px', 
    fontSize: isMobile ? 10 : 14,
    lineHeight: '1', 
    padding: `${isMobile ? '8px 12px' : '12px 12px'}`, 
    backgroundColor: '#569151', 
    borderColor: '#569151', 
    color: '#FFF' 
  });

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative', marginTop: isSmallMobile ? '55px' : (isMobile ? '65px' : 0) }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant={isMobile ? "subtitle2" : "h6"} component="div">
            Cart {cart?.lines?.edges?.length ? `(${cart.lines.edges.length})` : ''}
          </Typography>
          <CheckoutButton 
            autoFocus 
            color="inherit" 
            onClick={handleCheckout}
            disabled={!cart?.lines?.edges?.length}
          >
            Checkout
          </CheckoutButton>
        </Toolbar>
      </AppBar>
      <List>
        {!cart ? (
          <Typography sx={{ p: 2 }}>Loading...</Typography>
        ) : cart.lines?.edges?.length > 0 ? (
          cart.lines.edges.map((edge) => (
            <React.Fragment key={edge.node.id}>
              <Grid container alignItems="center">
                <Grid size={!isMobile ? 8 : 8}>
                  <ListItem>
                    <ListItemAvatar sx={{ marginRight: 2 }}>
                      <img 
                        src={edge.node.merchandise.image?.url || edge.node.merchandise.product.images.edges[0]?.node.url} 
                        alt={edge.node.merchandise.product.title}
                        style={{ width: 'auto', height: 50, objectFit: 'cover' }}
                      />
                    </ListItemAvatar>
                    <ListItemText 
                      primary={edge.node.merchandise.product.title}
                      secondary={`${edge.node.merchandise.price.amount} ${edge.node.merchandise.price.currencyCode}`}
                    />
                  </ListItem>
                </Grid>
                <Grid size={2}>
                  <Box display="flex" alignItems="center">
                    <ButtonGroup size="small">
                      <IconButton 
                        onClick={() => handleQuantityChange(edge.node.merchandise.id, edge.node.quantity, -1)}
                        size="small"
                      >
                        <RemoveIcon fontSize="small" />
                      </IconButton>
                      <Box
                        sx={{
                          px: 2,
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px solid',
                          borderColor: 'divider',
                          borderRadius: 0,
                          backgroundColor: 'background.paper'
                        }}
                      >
                        <Typography>{edge.node.quantity}</Typography>
                      </Box>
                      <IconButton 
                        onClick={() => handleQuantityChange(edge.node.merchandise.id, edge.node.quantity, 1)}
                        size="small"
                      >
                        <AddIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </Box>
                </Grid>         
                <Grid size={!isMobile ? 2 : 0}>
                  {!isMobile && (
                    <ListItemButton onClick={() => removeItemFromCart(edge.node.merchandise.id)}>
                      <ListItemText primary="Remove" />
                    </ListItemButton>
                  )}
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <Typography sx={{ p: 2 }}>Your cart is empty</Typography>
        )}
      </List>
    </Dialog>
  );
};

export default Cart;
