import React from 'react';
import ProductSearchCard from '../product-search-card/product-search-card.component';
import ProductSearchCardMui from '../product-search-card/product-search-card-mui.component';  
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import './product-search-grid.styles.css';

const ProductSearchGrid = ({ products, renderActionButton }) => {

  const NoProductsButton = styled(MuiButton)({
    width: '100%',
    borderRadius: '23px', 
    fontSize: 12,
    lineHeight: '1', 
    padding: '8px 12px', 
    backgroundColor: '#000', 
    border: '1px solid #FFF',
    color: '#FFF',
    cursor: 'pointer',
  });

  return (
    <div className={`product-search-grid-container`}>
      <div className="no-products-button-container">
        <div className="no-products-button-text">Don't see what you were looking for?</div>
        <NoProductsButton onClick={() => toast.info('We have received your request and will get back to you soon!')}>Request a product</NoProductsButton>
      </div>
      <div className={`product-search-grid`}>
        {products.map((product, idx) => (
          <ProductSearchCardMui
            key={idx}
            product={product}
            renderActionButton={renderActionButton}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductSearchGrid;
