import React, { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import Modal from 'react-modal';

import { CreatorsContext } from '../../context/creator.context';
import { useCtaContext } from '../../context/cta.context';
import { ProjectContext } from '../../context/project.context';
import { useCommandContext } from '../../context/command.context';
import { ProjectMessagesContext } from '../../context/messages.context';

import Spinner from '../spinner/spinner.component';

import ImageModal from '../image-modal/image-modal.component';
import CtaGroup from '../cta-group/cta-group.component';
import MiniSpinner from '../mini-spinner/mini-spinner.component';

import './chat-messages.styles.css';
import Button from '../button/button.component';
import { shopImage } from '../../utils/api/shop';


const s3_url = process.env.REACT_APP_S3_URL;
const creator_avatar = s3_url + '/avatars/generic_avatar.webp';

const ChatMessages = ({
  messages,
  messagesEndRef,
  loading,
  loadingMessage,
  onCtaClick,
  onVariation,
  onSelectCreator,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const { getCreatorProfileImage, currentCreator } =
    useContext(CreatorsContext);
  const { currentProject, setCurrentProject, projects, setProjects } =
    useContext(ProjectContext);
  const { activeCta, setActiveCta } = useCtaContext();
  const { activeCommand, setActiveCommand } = useCommandContext();
  const toggleImageClick = (event) => {
    setIsVisible(!isVisible);
  };
  const { projectCreators, addProjectCreator, isLoading } = useContext(
    ProjectMessagesContext,
  );

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (!currentProject) {
      setActiveCta('ctaActive');
      setActiveCommand('');
    } else {
      setActiveCta('ctaInactive');
    }
  }, [setActiveCta, currentProject, setCurrentProject]);


  const handleIntroClick = (e, action) => {
    e.preventDefault();
    // Handle different actions based on the data-action attribute
    switch(action) {
      case 'selectCreator':
        onSelectCreator(true);
        break;
      case 'designOnYourOwn':
        onCtaClick(true);
        break;
    }
  };

  const handleCtaClick = (visible) => {
    onCtaClick(visible);
  };

  const handleVariation = (newMessage) => {
    onVariation(newMessage);
  };

  const getMessageContent = (message) => {
    return message.content[0].text.value;
  };

  const getImageUrls = (message) => {
    if (!message.metadata || !message.metadata.images) {
      return [];
    }
    try {
      return JSON.parse(message.metadata.images);
    } catch (error) {
      console.error('Error parsing image URLs:', error);
      return [];
    }
  }

  const creatorProfileImage = (creatorId) => {
    return projectCreators.find((creator) => creator.id === creatorId)?.profile_image_url || creator_avatar;
  };

  const getBaseImage = (message) => {
    if (!message.metadata || !message.metadata.base_image || !message.metadata.variationImageUrl) {
      return null;
    }
    try {
      console.log('message.metadata', message.metadata);
      if (message.metadata.variationImageUrl) {
        console.log('variationImageUrl', message.metadata.variationImageUrl);
        return message.metadata.variationImageUrl;
      }
      return message.metadata.base_image;
    } catch (error) {
      console.error('Error getting base image:', error);
      return null;
    }
  }

  const imageDeliveryPhrases = [
    "Here you go!",
    "Here's what I created!",
    "Ta-da! ✨",
    "Fresh off the canvas!",
    "Check this out!",
    "Voilà!",
    "Just for you!",
    "What do you think?",
    "Ready to see?",
    "All done!"
  ];

  return (
    <div className="chat-messages">
      <CtaGroup
        visible={activeCta === 'ctaActive'}
        onCtaClick={handleCtaClick}
      />
      {/* filter out messages with display: false */}
      {messages.filter(message => 
        !message.metadata || 
        !message.metadata.hasOwnProperty('display') || 
        message.metadata.display !== 'false'
      ).map((message, index, array) => {
        if (!message) {
          return <div>undefined message </div>;
        }
        const previousMessage = index > 0 ? array[index - 1] : null;
        const messageContent = getMessageContent(message);
        const imageUrls = getImageUrls(message);
        const baseImage = getBaseImage(message);
        
        return (
          <div
            key={index}
            className={`chat-message-container ${message.role === 'user' ? 'user-message' : 'ai-message'} ${!loading && index === messages.length - 1 ? 'last-message' : ''}`}
          >
            {message.role === 'assistant' && (
              <img
                src={creatorProfileImage(previousMessage?.metadata?.creator_id)}
                alt="AI Avatar"
                className="chat-avatar"
              />
            )}
            <div
              className={`chat-message ${message.role === 'user' ? 'user-message' : 'ai-message'}`}
            >
              {/* <div
                dangerouslySetInnerHTML={{ __html: getMessageContent(message) }}
              /> */}
              <div className="markdown-content">
                {imageUrls.length === 0 && (
                  <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    a: ({ node, ...props }) => (
                      <a
                        {...props}
                        onClick={(e) => handleIntroClick(e, props['data-action'])}
                        style={{ cursor: 'pointer' }}
                      />
                    ),
                  }}
                 >
                    {messageContent}
                  </ReactMarkdown>
                )}
              </div>
              {imageUrls.length > 0 && (
                <>
                  {message.role === 'assistant' && (
                    <p className="markdown-images-text">{imageDeliveryPhrases[Math.floor(Math.random() * imageDeliveryPhrases.length)]}</p>
                  )}
                  <div className="markdown-images">
                  {imageUrls.map((url, idx) => (
                    <ImageModal
                    key={idx}
                    imageUrl={url}
                    initialIndex={idx}
                    imageArray={imageUrls}
                    onClick={toggleImageClick}
                    handleVariation={handleVariation}
                    prompt={previousMessage?.content[0].text.value}
                      />
                    ))}
                  </div>
                </>
              )}
              {message.sender === 'assistant' && message.imageUrl && (
                <div className="both-images">
                  <div className="result-image-container">
                    {message.imageUrl.map((image, idx) => (
                      <ImageModal
                        key={idx}
                        imageUrl={image}
                        initialIndex={idx}
                        imageArray={message.imageUrl}
                        className={`response-image ${isVisible ? 'visible' : ''}`}
                        onClick={toggleImageClick}
                      />
                    ))}
                  </div>
                  {isVisible && message.imageUrl2 && (
                    <div className="shop-result-container">
                      <img
                        src={message.imageUrl2}
                        alt="AI Response"
                        className="shop-result"
                      />
                    </div>
                  )}
                </div>
              )}
              <div className="user-images">
                {message.role === 'user' && baseImage && (
                  <div className="base-image-container">
                    <img src={baseImage} alt="" className="user-image" />
                  </div>
                )}
                {message.role === 'user' && message.styleImageUrl && (
                  <div className="base-image-container">
                    <img
                      src={message.styleImageUrl}
                      alt=""
                      className="user-image"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {loading && (
        <div className="chat-message-container ai-message">
          <img
            src={currentCreator?.profile_image_url || creator_avatar }
            alt="AI Avatar"
            className="chat-avatar"
          />
          <div className={'chat-message ai-message fading-text'}>
            {loadingMessage === 'generating image...' ? (
              <MiniSpinner />
            ) : (
              <ReactMarkdown>{loadingMessage}</ReactMarkdown>
            )}
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default ChatMessages;
