import React, { createContext, useState, useEffect, useContext } from 'react';
import { addToCart, createCart, getCart, removeFromCart, updateCartLines } from '../utils/api/shopify';

export const CartContext = createContext({
  cart: null,
  setCart: (cartData) => {},
  addItemToCart: (variantId) => {},
  isVariantInCart: (variantId) => false,
  removeItemFromCart: (variantId) => {},
  updateItemQuantity: (variantId, quantity) => {},
});

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(null);

  useEffect(() => {
    const initializeCart = async () => {
      let cartData = await getCart();
      if (!cartData) {
        cartData = await createCart();
        localStorage.setItem('cartId', cartData.id);
      }
      setCart(cartData);
    };
    initializeCart();
  }, []);

  const addItemToCart = async (variantId) => {
    try {
      const cartData = await addToCart(cart.id, variantId);
      setCart(cartData);
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  const isVariantInCart = (variantId) => {
    if (!cart?.lines?.edges) return false;
    return cart.lines.edges.some(edge => edge.node.merchandise.id === variantId);
  };

  const removeItemFromCart = async (variantId) => {
    if (!cart?.lines?.edges) {
      console.error('Cart is not properly initialized');
      return;
    }

    const lineItem = cart.lines.edges.find(edge => edge.node.merchandise.id === variantId);
    if (!lineItem) {
      console.error('Line item not found for variant:', variantId);
      return;
    }

    try {
      const lineId = lineItem.node.id;
      const cartData = await removeFromCart(cart.id, lineId);
      setCart(cartData);
    } catch (error) {
      console.error('Error removing item from cart:', error);
    }
  };

  const updateItemQuantity = async (variantId, quantity) => {
    if (!cart?.lines?.edges) {
      console.error('Cart is not properly initialized');
      return;
    }

    const lineItem = cart.lines.edges.find(edge => edge.node.merchandise.id === variantId);
    if (!lineItem) {
      console.error('Line item not found for variant:', variantId);
      return;
    }

    try {
      const lineId = lineItem.node.id;
      const cartData = await updateCartLines(cart.id, lineId, quantity);
      setCart(cartData);
    } catch (error) {
      console.error('Error updating item quantity:', error);
    }
  };

  return (
    <CartContext.Provider value={{ 
      cart, 
      setCart, 
      addItemToCart, 
      isVariantInCart, 
      removeItemFromCart,
      updateItemQuantity 
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
